.App {
  text-align: center;

  font-family: Roboto;
  font-size: x-large;

  height: 100vh;
  width: 100vw;

  display: flex;
  flex-flow: column;

  /* Color Variables */
  --color1: #F8E9A1;
  --color2: #F76C6C;
  --color3: #FAFAFA;
  --color4: #E2E1FF;
  --color5: #25305E;
  --color6: #18142F;

  color: var(--color6);
}

.Content {
  background-color: var(--color4);
  flex: 1;
  overflow: auto;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
