.Profile {
  background: rgb(225,203,231);
  background: linear-gradient(180deg, rgba(225,203,231,1) 0%, rgba(250,246,251,1) 100%); 
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.Profile .Back {
  background: #00000011;
  margin-bottom: 2em;
  border: 0.15em solid #333;
  border-radius: 0.3em;
  cursor: pointer;
  padding: 0.5em;
}

.Profile .Back:hover {
  background: #00000044;
}

.Profile-Content {
  display: flex;
  max-width: 45em;
  margin: auto;
  flex-wrap: wrap;
  margin-top: 2em
}

.Profile .Info {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding-right: 2em;
  margin-left: 1em;
}


.Profile .Description {
  flex: 1 1;
  /*background-color: #FFFFFF44;*/
  margin: 1em;
  text-align: left;
  border-radius: 0.5em;
  /*overflow: auto;*/
  /*box-shadow: inset 0 -2px 5px 0 black;*/
  padding: 0 1em;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.Description .padding {
  padding-bottom: 1em;
}

.Profile-Image img {
  object-fit: cover;
  max-height: 10em;
  max-width: 50vw;
  width: auto;
  border-radius: 8px;
  box-shadow: 1px 1px 15px 1px #555;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.Profile .Name {
  font-size: medium;
  align-items: flex-start;
}

.Profile .Title {
  font-size: small;
  align-items: flex-start;
  padding-bottom: 0.5em;
}

.Name h1 {
  font-family: sans-serif;
  padding: 0em;
  margin: 0em;
}

.Title h2 {
  font-family: serif;
  padding: 0em;
  margin: 0em;
}

.Description h2 {
  padding-top: 0;
  margin-top: 0;
  text-align: center;
  font-family: serif;
}
